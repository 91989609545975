<template>
  <div ref="container" class="container" :style="{height: `${chartHeight}px`, marginRight:'48px', marginTop: '24px'}" v-show="items && items.length"></div>
</template>

<script>
import { remoteLoad } from '@/common/utils'
import { DOWNLOAD_URL } from '@/api/config'
import miment from 'miment'
export default {
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    demo: {
      type: Boolean,
      default: false
    },
    chartWidth: {
      type: Number,
      default: 350
    },
    title: {
      type: String,
      default: ''
    },
    chartHeight: {
      type: Number,
      default: 180
    }
  },
  data() {
    return {
      mountedFlg: false
    }
  },
  mounted() {
    if (!window.CanvasJS) {
      remoteLoad(`${DOWNLOAD_URL}static/js/canvasjs.min.js`).then(this.renderCanvasJs)
    } else {
      setTimeout(() => {
        this.renderCanvasJs()
      }, 25)
    }
    this.mountedFlg = true
  },
  watch: {
    items() {
      if (!this.mountedFlg) {
        return
      }
      setTimeout(() => {
        this.renderCanvasJs()
      }, 25)
    }
  },
  methods: {
    renderCanvasJs() {
      if (!this.items || !this.items.length) {
        return
      }

      let data = []
      let dataPoints1 = []
      let dataPoints2 = []
      let dataPoints3 = []
      let dataPoints4 = []
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        dataPoints1.push({ x: miment(item.RcvTime), y: item.Temperature1 })
        dataPoints2.push({ x: miment(item.RcvTime), y: item.Temperature2 })
        dataPoints3.push({ x: miment(item.RcvTime), y: item.Temperature3 })
        dataPoints4.push({ x: miment(item.RcvTime), y: item.Temperature4 })
      }
      data.push({ type:'line', markerSize: 5, xValueFormatString: `'${this.$t('reportQuery.time')}'：YYYY-MM-DD HH:mm:ss`, yValueFormatString: `${this.$t('reportQuery.temperature')}：##.0°C`, dataPoints: dataPoints1 })
      data.push({ type:'line', markerSize: 5, xValueFormatString: `'${this.$t('reportQuery.time')}'：YYYY-MM-DD hh:mm:ss`, yValueFormatString: `${this.$t('reportQuery.temperature')}：##.0°C`, dataPoints: dataPoints2 })
      data.push({ type:'line', markerSize: 5, xValueFormatString: `'${this.$t('reportQuery.time')}'：YYYY-MM-DD hh:mm:ss`, yValueFormatString: `${this.$t('reportQuery.temperature')}：##.0°C`, dataPoints: dataPoints3 })
      data.push({ type:'line', markerSize: 5, xValueFormatString: `'${this.$t('reportQuery.time')}'：YYYY-MM-DD hh:mm:ss`, yValueFormatString: `${this.$t('reportQuery.temperature')}：##.0°C`, dataPoints: dataPoints4 })


      window.CanvasJS.addColorSet("customColorSet1",
      [//colorSet Array
        "#67C23A",
        "#FA5555",
        "#3EA0DD",
        "#23BFAA",
        "#B08BEB",
        "#667AE6",
        "#F5A52A",
        "#23BFAA",
        "#FAA586",
        "#EB8CC6"
      ]);

      let chart = new window.CanvasJS.Chart(this.$refs.container, {
        zoomEnabled: true,
        colorSet: "customColorSet1",
        //theme: "light1", // "light1", "light2", "dark1", "dark2"
        exportEnabled: true,
        animationEnabled: true,
        title: {
          text: this.title,
          fontSize: 18,
          padding: {
            top: 12,
            right: 12,
            bottom: 24,
            left: 12
          }
        },
        legend: {
          fontSize: 13
        },
        backgroundColor: '#FFFFFF',
        data,
        axisY: {
          suffix: "°C",
          margin: 48,
          labelFontSize: 14,
          labelFontColor: 'rgba(0, 0, 0, .54)',
          gridColor: 'rgba(0, 0, 0, .26)'
        },
        axisX: {
          valueFormatString: "YYYY-MM-DD HH:mm:ss",
          intervalType: 'hour',
          interval: 3,
          margin: 24,
          labelFontSize: 14,
          labelFontColor: 'rgba(0, 0, 0, .54)',
          gridColor: 'rgba(0, 0, 0, .26)'
        }
      })
      chart.render()
    }
  }
}
</script>

<style lang="scss" scoped>
.container ::v-deep {
  .canvasjs-chart-toolbar {
    right: -7px !important;
    top: -7px !important;
    button {
      background-color: #F4F4F8 !important;
      &:hover {
        background-color: #CC3333 !important;
      }
    }
  }
  .canvasjs-chart-credit {
    display: none !important;
  }
}
</style>
